import React, { useEffect, useState } from "react";
import Work from "../components/molecules/work";
import { Col, Row, Spinner } from "react-bootstrap";
import ButtonOutline from "../components/atoms/buttonoutline";
import "react-loading-skeleton/dist/skeleton.css";
import db from "../firebase";
import { collection, getDocs, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore'


const Works = () => {

  const [limit, setLimit] = useState(3); 
  
  const [works, setWorks] = useState<Work[]>([]);
  interface Work {
    type: string;
    title: string;
    status:string;
    name:string;
    accomplishments:string[];
    tags:string[];
    img:string[];
  }


  const getWorks= async () => {
    try {
      const datos = await getDocs(collection(db, 'works'));
      const docs: Work[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as Work);
      });
      console.log("soy docs:",docs)
      setWorks(docs);
      
    } catch (error) {
      console.error('Error al obtener productos:', error);
    }
  };


useEffect (() => {getWorks()},[]) 




  return (
    <div className="work-section mt-100">
        <Row className="work-title">
        <Col md={4}>
          <h1 className="text-white">Work</h1>
        </Col>
        <Col md={8} className="text-gray align-self-center text-end">
          <a href="tel:+91-90048-55805">(+91) 90048-55805</a>
          <span className="divider">|</span>
          <a href="mailto:smeetmak@gmail.com">smeetmak@gmail.com</a>
        </Col>
      </Row>
      <div>
        {works.slice(0, limit).map((data) => (
          <Work key={data.title} data={data} /> 
          
       ))}
      </div>
      <div className="d-flex justify-content-center mt-60">
        {limit < works.length && ( // Verifica si se han mostrado todos los trabajos
          <ButtonOutline
            width="167px"
            text="LOAD MORE"
            outline="outline-light"
            handleClick={() => {
              setLimit(limit + 3);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Works;
