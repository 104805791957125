export async function authGateway(METHOD, URL, BODY, formData = false) {


    const TOKEN = localStorage.getItem("sm-magic");


    const OPTIONS = {
        method: METHOD,
        headers: {
            ...(!formData) && { 'Content-Type': 'application/json' },
            'x-access-token': TOKEN,
        },
        body: BODY === "" ? null : BODY,
    };
    try {
        const response = await fetch(URL, OPTIONS);
        const parsedResponse = await handleResponse(response);
        if (!parsedResponse.success) {
          console.error("Error in authGateway1:", parsedResponse.message);
        }
        return parsedResponse;
      } catch (error) {
      
        return { success: false, message: "An error occurred during the request." };
      }
    }


    export async function guestGateway(METHOD, URL, BODY = "") {
      const OPTIONS = {
        method: METHOD,
        headers: {
          'Content-Type': 'application/json',
        },
        body: BODY === "" ? null : BODY,
      };
    
      try {
        console.log("Making the fetch request to URL:", URL);
        const response = await fetch(URL, OPTIONS);
        const parsedResponse = await handleResponse(response);
        if (!parsedResponse.success) {
          console.error("Error in guestGateway3:", parsedResponse.message);
        }
        return parsedResponse;
      } catch (error) {
        return { success: false, message: "An error occurred during the request." };
      }
    }



async function handleResponse(response) {
    const text = await response.text();
    try {
        return JSON.parse(text);
    } catch (error) {
        return { success: false, message: "Invalid JSON response from the server." };
    }
}