import React, { useEffect, useState } from 'react'
import { Carousel, Modal } from 'react-bootstrap';
import ButtonRound from './buttonround';
import { useMediaQuery } from 'react-responsive';


interface WorkInterface {
    name: string
}

const WorkCarousel = (props: any) => {
    const [index, setIndex] = useState(0);
    const [show, setShow] = useState(false);

    const isLargeScreen = useMediaQuery({ minWidth: 650 });

    useEffect(() => {
        
        if (props.show == true) {
            setShow(props.show);
        }
    }, [props.show])

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    const handleShowModal = () => {
        props.handleShow(false);
        setShow(false);
    }


    const { name, type, tags, img, status } = props.data;
    
    console.log("data carrousel",props.data)

    const filteredImages = isLargeScreen
    ? img.filter((img: string) => img.includes('computer') || img.includes('Computer'))
    : img.filter((img: string) => img.includes('mobile') || img.includes('Mobile'));

    return (
        <Modal show={show} className='work-carousel' fullscreen={true} centered onHide={() => handleShowModal()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='d-flex'>
                        <h4>{name}</h4>
                        <div className='pill-orange'>{status}</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-between mb-1 work-desc'>
                    <p className='color-gray text-small'>{type}</p>
                    <div className='display-chips d-flex'>
                    {tags.map((button: any, i: number) => <span key={i}><ButtonRound name={button} /></span>)}
                    </div>
                </div>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                {filteredImages.map((image: string) => {
                 return (
                 <Carousel.Item key={image}>
              <img
                className="d-block w-100"
                src={image}
                alt="Carousel slide"
              />
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                <div className='d-flex img-sm mt-2'>
                    {filteredImages.map((image: string, i: number) => <div key={i} className='img-container'><img onClick={() => setIndex(i)} className={index == i ? 'img-active' : 'img-not-active'} src={image}/></div>)}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default WorkCarousel