import React, { useEffect, useState } from 'react'
import { Accordion, Container } from 'react-bootstrap'
import { collection, getDocs, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore'
import db from "../../firebase";

const FAQ = (props: any) => {
 
  const [faqList, setFaqList] = useState<Question[]>([]);


  useEffect(() => {
    getFaqList();
  }, []);

  interface Question {
    question: string;
    answer: string;
  }

  const getFaqList = async () => {
    try {
      const datos = await getDocs(collection(db, 'questions'));
      const docs: Question[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as Question);
      });

      setFaqList(docs);
      
    } catch (error) {
      console.error('Error finding questions:', error);
    }
  }

  return (
    <Container fluid className='faq-section' id="faq">
      <div className='m-auto faq'>
        <h2 className='text-white faq-heading'>Frequently Asked Questions</h2>
        <Accordion flush>
          {faqList.map((faq: any, index: number) => {
            return (
              <Accordion.Item key={index} eventKey={"key-" + index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body className='text-gray' dangerouslySetInnerHTML={{__html:faq.answer}}></Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
        <p className='text-gray mt-40'>Didn’t find the answer you need? If you have any additional questions feel free to shoot me a message at:<br/><a href="mailto:smeetmak@gmail.com"><span className='color-orange'>smeetmak@gmail.com</span></a></p>
      </div>
    </Container>
  )
}

export default FAQ