import React, { useState } from "react";
import { Col, Container, Row} from "react-bootstrap";
import ContactImg from "../assets/images/contact-img.png";
import ContactImg2 from "../assets/images/contact-img2.png";
import ContactImgTwo from "../assets/images/contact-img-two.png";
import FAQ from "../components/molecules/faq";
import ButtonDark from "../components/atoms/buttondark";
import animationData from "../assets/animations/scroll-down-animation.json";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';


import { useForm, ValidationError } from '@formspree/react';


const Contact = () => {

const isMobile = useMediaQuery({ minWidth: 1045 });

const [isSubmit, setIsSubmit] = useState(false);

const [state, handleSubmit] = useForm("xdorwdvd");




  const handleScroll = () => {
    const element = document.getElementById("faq");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const customSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Evita el envío predeterminado del formulario
    await handleSubmit(e); // Llama a la función handleSubmit de Formspree
    setIsSubmit(true); // Cambia el estado después del envío exitoso
  };

  return (
    <>
      <Container fluid className="contact-page">
        <div className="position-relative">
          <div className="d-flex justify-content-center">
            <Lottie
              className="scroll-down-animation"
              animationData={animationData}
              loop={true}
            />
          </div>
          {!isSubmit ? (
            <Row className="contact">
              <Col md={7} className="contact-form">
                <h4>Got ideas? Let’s partner up.</h4>
                <div className="color-gray">
                  Reach me anytime at{" "}
                  <span>
                    <a href="mailto:smeetmak@gmail.com">smeetmak@gmail.com</a>
                  </span>{" "}
                  or{" "}
                  <span>
                    <a href="tel:+91-90048-55805">+91 9004855805</a>
                  </span>
                </div>

                <form action="https://formspree.io/f/xdorwdvd" method="POST" onSubmit={customSubmit}>
                <div id='firstRow'>
                <div className='input_cont'>
                  <label className="label" htmlFor="name">
                    Name *
                  </label>
                  <input
                    placeholder='Your Name'
                    id="name"
                    type="text " 
                    name="name"
                  />
                  <ValidationError 
                    prefix="name" 
                    field="name"
                    errors={state.errors}
                  />
                  </div>     
                  <div className='input_cont'>
                    <label className="label" htmlFor="email">
                      Email *
                    </label>
                    <input
                      placeholder='xyz@company.com'
                      id="email"
                      type="email" 
                      name="email"
                    />
                    <ValidationError 
                      prefix="Email" 
                      field="email"
                      errors={state.errors}
                    />
                  </div>
                  </div>
      
                  <div className='input_cont' style={{width:"97%"}}>
                    <label htmlFor="need_help_with"  className="label"  style={{ marginBottom:"1%"}}  >
                      How can I help?
                    </label>
                    <input style={{ paddingBottom:"1%"}}
                      placeholder='Tell me about your project…'
                      id="email"
                      type="need_help_with"
                      name="need_help_with"    
                    />

                    <ValidationError 
                      prefix="Message" 
                      field="need_help_with"
                      errors={state.errors}
                    />
                  </div>

              <div className='input_cont' style={{marginBottom:"2%", width:"105%"}}>
              <label className="label">I'm interested in...</label>
                <div className='cb_cont'>
                 <div className="c1">
                 <div>
                  <input type="checkbox" id="Website Design" name="interested_in" value="Website Design " />
                  <label htmlFor="Website Design">Website Design</label>
                  </div>
                  <div>
                  <input type="checkbox" id="UX/UI Design" name="interested_in" value="UX/UI Design " />
                  <label htmlFor="">UX/UI Design</label>
                  </div>
                  <div>
                  <input type="checkbox" id="UX Evaluation" name="interested_in" value="UX Evaluation " />
                  <label htmlFor="UX Evaluation">UX Evaluation</label>
                  </div>
                 </div>
                <div className="c2">
                <div>
                  <input type="checkbox" id="Brand Design" name="interested_in" value="Brand Design " />
                  <label htmlFor="Brand Design">Brand Design</label>
                  </div>
                  <div>
                  <input type="checkbox" id="Design System" name="interested_in" value="Design System " />
                  <label htmlFor="Design System">Design System</label>
                  </div>
                  <div>
                  <input type="checkbox" id="Other" name="interested_in" value="Other " />
                  <label htmlFor="Other">Other (Please specify in message)</label>
                  </div>          
                  </div>
                  </div>     
                </div>
                 
                   
              <button style={{padding:"0"}} type="submit"><ButtonDark text="LET'S GET STARTED" /></button>
           </form>
              
              </Col>
              <Col md={5} className="p-0">
              <img className="h-100 w-100" src={isMobile ? ContactImg : ContactImg2} alt="Contact" />
              </Col>
            </Row>
          ) : (
            <Row className="d-flex contact-confirm">
              <Col md={7} className="contact-submit">
                <h3>
                  Thank You for your time!
                  <br />
                  I’ve received your message.
                </h3>
                <p className="text-gray mt-4">
                  I’m excited to learn more about your project. I will
                  personally respond and acknowledge your request via email
                  within 24 hours.
                </p>
                <p className="text-gray mt-4">
                  In the meantime, you can check out the following:
                </p>
                <ul className="color-orange mb-5">
                  <li onClick={() => handleScroll()}>
                    Frequently Asked Questions
                  </li>
                  <li>
                    <Link to="/work">My Work</Link>
                  </li>
                  <li>
                    <Link to="/about">About Me</Link>
                  </li>
                </ul>
                <Link to="/">
                  <ButtonDark width="196px" text="BACK TO HOME" />
                </Link>
              </Col>
              <Col md={5}>
                <img src={ContactImgTwo} />
              </Col>
            </Row>
          )}
        </div>
      </Container>
      <FAQ />
    </>
  );
};

export default Contact;
