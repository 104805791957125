import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Services from '../components/molecules/services'
import Work from '../components/molecules/work'
import ButtonOutline from '../components/atoms/buttonoutline'
import DesignProcess from '../components/molecules/designprocess'
import Faq from '../components/molecules/faq'
import HomeBanner from '../components/molecules/homebanner'
import { Link } from 'react-router-dom'
import db from "../firebase";
import { collection, getDocs, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore'

const Home = () => {

// const [serviceList, setServiceList] = useState([]);

const [firstsWorks, setFirstWorks] = useState<Work[]>([]);
const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
   getWorks();
   getServices();
  }, []);
  

  interface Service {
    name: string;
    desc: string;
    img: string;
    list: string[];
  }
  interface Work {
    type: string;
    title: string;
    status:string;
    name:string;
    accomplishments:string[];
    tags:string[];
    img:string[];
  }


  const getWorks= async () => {
    try {
      const datos = await getDocs(collection(db, 'works'));
      const docs: Work[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as Work);
      });

      const firstThreeElements = docs.slice(0, 3);
      const newArray = [];  
      newArray.push(...firstThreeElements);
      setFirstWorks(newArray);
      
    } catch (error) {
      console.error('Error finding works:', error);
    }
  };

  const getServices= async () => {
    try {
      const datos = await getDocs(collection(db, 'services'));
      const docs: Service[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as Service);
      });

      setServices(docs);
      
    } catch (error) {
      console.error('Error finding services:', error);
    }
  };

  


  return (
    <>
      <HomeBanner/>

      <Services services={services} />
     
      <div className='work-section'>
        <Row className='work-title'>
          <Col md={5}><h1 className='text-white'>Work</h1></Col>
          <Col md={7} className="text-gray">During the last half-decade, I have been privileged to work with many enterprises of varying sizes, from <span className='color-white'>growing startups to multinationals</span>, across <span className='color-white'>10+ industries</span>. Here's a glimpse into some of my work.</Col>
        </Row>
        <div>
        {firstsWorks.map(data => <Work data={data} key={data.title}/>)}
          
        </div>
        
        <div className="d-flex justify-content-center mt-60">
        <Link to="/work"><ButtonOutline width="164px" text="VIEW MORE" outline="outline-light" /></Link>
        </div>
      </div>
      <DesignProcess />
      <Faq/>
    </>
  )
}

export default Home