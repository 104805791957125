import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyC42nPXfCbgTR3fMmXwLSPsf8TdXlEDFu4",
    authDomain: "smeetmakwana-a91aa.firebaseapp.com",
    projectId: "smeetmakwana-a91aa",
    storageBucket: "smeetmakwana-a91aa.appspot.com",
    messagingSenderId: "1051339443651",
    appId: "1:1051339443651:web:b3ff802ec171a3b2b94176",
    measurementId: "G-B6BH1RZYB4"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
 const db = getFirestore(app);
 export default db;

