import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Value from "../components/atoms/value";
import Tool from "../components/atoms/tool";
import ButtonDark from "../components/atoms/buttondark";
import Lottie from "lottie-react";
import animationData from "../assets/animations/scroll-down-animation.json";
import { Link } from "react-router-dom";
import { collection, getDocs, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore'
import db from "../firebase";

const About = () => {

  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [values, setValues] = useState<Value[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);
  const [pp, setPp] = useState<PP[]>([]);

  interface Experience {
    company: string;
    designation: string;
    year: string;
  }

  interface Value{
    company: string;
    designation: string;
    year: string;
  }

  interface Tool{
   name:string;
   img:string;
  }

  interface PP {
   img:string;
  }

  useEffect(() => {
    getExperiences();
    getValueObjects();
    getTools();
    getPP();
  }, []); 

  const getExperiences= async () => {
    try {
      const datos = await getDocs(collection(db, 'experience'));
      const docs: Experience[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as Experience);
      });

      setExperiences(docs);
      
    } catch (error) {
      console.error('Error finding experiences:', error);
    }
  };

  const getValueObjects = async () => {
    try {
      const datos = await getDocs(collection(db, 'values'));
      const docs: Value[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as Value);
      });

      setValues(docs);
      
    } catch (error) {
      console.error('Error finding value objects:', error);
    }
  };

  const getTools = async () => {
    try {
      const datos = await getDocs(collection(db, 'tools'));
      const docs: Tool[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as Tool);
      });

      setTools(docs);
      
    } catch (error) {
      console.error('Error finding tools:', error);
    }
  };

  const getPP = async () => {
    try {
      const datos = await getDocs(collection(db, 'passionObjects'));
      const docs: PP[] = [];

      datos.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        docs.push({ ...doc.data()} as PP);
      });

      setPp(docs);
      
    } catch (error) {
      console.error('Error finding tools:', error);
    }
  };

  return (
    <Container fluid className="p-0">
      <div className="about-banner position-relative">
        <div className="d-flex justify-content-center">
          <Lottie
            className="scroll-down-animation"
            animationData={animationData}
            loop={true}
          />
        </div>
        <div className="about-heading">ABOUT ME</div>
        <Row>
          <Col md={6}>
            <p className="color-gray">
              My expertise lies in leveraging current trends to design websites, applications and brand identities that are optimised for maximum engagement, reach and conversions. </p>
              <p className="color-gray">
              I work not just for my clients but with them, and strive to understand the deeper business needs to tailor my outcomes accordingly. </p>
              <p className="color-gray">
              During the last half-decade, I have been privileged to work with many enterprises of varying sizes, from growing startups to multinationals, across 10+ industries. </p>
          </Col>
        </Row>
        <div className="d-inline-block">
          <p className="mt-2 fw-600 fs-24px">
            Bringing life to your digital experiences.
          </p>
          <div className="d-flex color-orange mb-3">
            <div className="d-flex w-100">
              <hr className="hr-length align-self-center" />
              <p className="text-nowrap">
                Your Design partner from Mumbai, India.
              </p>
            </div>
          </div>
        </div>
        <Link to="/contact">
          <ButtonDark width="185px" text="GET IN TOUCH" />
        </Link>
      </div>
      <div className="values-section">
        <div className="heading-orange mb-10px">WHAT DRIVES ME</div>
        <h2 className="fw-600 text-light text-center">Values & Beliefs</h2>
        <div className="values mt-5">
          {values.map((data) => (
            <Value data={data} />
          ))}
        </div>
        <div className="heading-orange mt-120px mb-10px">
          CONVERTING IDEAS INTO RELAITY
        </div>
        <h2 className="fw-600 text-light text-center">Tools & Services</h2>
        <div className="tools mt-5">
          {tools.map((data) => (
            <Tool data={data} />
          ))}
        </div>
        <p className="text-gray text-center mt-3">And more...</p>
      </div>
      <div className="passion-section text-center">
        <h2>Passion Projects</h2>
        <p className="text-gray mb-5 mt-24">
          Here’s a small photo dump of something I occasionally dabble with.
          Painting, drawing and
          <br />
          digital sketching allows me to think beyond limits, polishing my
          creativity.
        </p>
        <Row>
          {pp.map((project) => (
            <Col md={4} className="mb-4">
              <img src={project.img} alt="passionObject" />
            </Col>
          ))}
        </Row>
      </div>
      <div className="work-experience-section">
        <Row>
          <Col md={6}>
            <h1 className="text-light">Work Experience</h1>
          </Col>
          <Col md={6}>
            <p className="text-gray">
              Over my <span className="text-light">5+ years</span> in the field,
              I have honed my keen eye for detail, which extends to my approach
              and results. In addition to my experience of working on{" "}
              <span className="text-light">wide array of projects</span>, I’ve
              also been taking on freelancing assignments.
            </p>
          </Col>
        </Row>
        <div className="vertical-line">
          {experiences.slice(0).reverse().map((data) => {
            return (
              <div  className="mb-5 d-flex">
                <div className="year-section color-orange fw-600 align-self-center">
                  <div className="year">
                    <p>{data.year}</p>
                  </div>
                </div>
                <div>
                  <h6 className="text-light main-heading">{data.company}</h6>
                  <h6 className="fw-300 color-gray sub-heading">
                    {data.designation}
                  </h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default About;
